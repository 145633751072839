.App-header {
    padding: 0 50px;
}

ul li {
    text-align: left;
}

h1,
h2,
h3,
p {
    margin: 0 0 1%
}

.cont {
    padding: 10px;
}

// forms

/* Add this CSS in a separate .css file or in a <style> tag within your component */

.form_cont {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 0 100px;
}

form {
    width: 100%;
    margin: 0 auto;
}

label {
    font-weight: bold;
    padding: 0 0 5px;
    display: block;
}

input[type="text"],
input[type="email"],
textarea {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 100%;
    transition: border-color 0.2s ease-in-out;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
    outline: none;
    border-color: #007bff;
}

button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}